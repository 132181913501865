exports.components = {
  "component---src-templates-advanced-page-template-index-js": () => import("./../../../src/templates/AdvancedPageTemplate/index.js" /* webpackChunkName: "component---src-templates-advanced-page-template-index-js" */),
  "component---src-templates-article-page-template-index-js": () => import("./../../../src/templates/ArticlePageTemplate/index.js" /* webpackChunkName: "component---src-templates-article-page-template-index-js" */),
  "component---src-templates-brand-page-template-index-js": () => import("./../../../src/templates/BrandPageTemplate/index.js" /* webpackChunkName: "component---src-templates-brand-page-template-index-js" */),
  "component---src-templates-campaign-page-template-folkofolk-index-js": () => import("./../../../src/templates/CampaignPageTemplate/folkofolk/index.js" /* webpackChunkName: "component---src-templates-campaign-page-template-folkofolk-index-js" */),
  "component---src-templates-category-page-template-index-js": () => import("./../../../src/templates/CategoryPageTemplate/index.js" /* webpackChunkName: "component---src-templates-category-page-template-index-js" */),
  "component---src-templates-landing-page-template-index-js": () => import("./../../../src/templates/LandingPageTemplate/index.js" /* webpackChunkName: "component---src-templates-landing-page-template-index-js" */),
  "component---src-templates-product-page-template-index-js": () => import("./../../../src/templates/ProductPageTemplate/index.js" /* webpackChunkName: "component---src-templates-product-page-template-index-js" */),
  "component---src-templates-recipe-page-template-index-js": () => import("./../../../src/templates/RecipePageTemplate/index.js" /* webpackChunkName: "component---src-templates-recipe-page-template-index-js" */)
}

